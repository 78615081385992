import type { SaleRequestTemplateData } from '@/api/generated/base'
import { EVariablesKind } from '@/types'
import { EPurchaseStatus } from '@/types/EPurchaseStatus'
import type { ITemplate, TVariableDeliveryBases } from '@/types/HarvestSaleLots'
import { EFcaPriceStatus } from '@/types/HarvestSaleLots'
import { EFcaCalculateSettings } from '@/types/IOrganization'
import { CROPS_IDS } from './constants'
import type { TCultureName } from './types'

export const getCropsImage = (id: string) => {
  if (CROPS_IDS.includes(id as TCultureName)) return `index/outputs/${id}.jpg`

  return 'index/placeholder.png'
}

// TODO: OUT-1115 удалить enumFromStringValue, deliveryBasesTypeCheck, handleTemlateApiResponse
function enumFromStringValue<T>(enm: { [s: string]: T }, value?: string): T | undefined {
  if (!value) return undefined

  return (Object.values(enm) as string[]).includes(value) ? (value as T) : undefined
}

function deliveryBasesTypeCheck(string?: string): TVariableDeliveryBases {
  const daliveryBases = ['elevator', 'exw', 'fob', 'pickup', 'wagon'] satisfies TVariableDeliveryBases[]

  if (string && daliveryBases.includes(string as TVariableDeliveryBases)) return string as TVariableDeliveryBases

  return 'exw'
}

export const handleTemlateApiResponse = (template: SaleRequestTemplateData): ITemplate => ({
  ...template,
  fca: {
    status: enumFromStringValue(EFcaPriceStatus, template.fca.status),
    price: template.fca.price,
  },
  buyer: {
    ...template.buyer,
    fcaCalculateSetting: enumFromStringValue(EFcaCalculateSettings, template.buyer.fcaCalculateSetting),
    deliveryBasesData: template.buyer.deliveryBasesData.map(({ id, abbreviation, active, name }) => ({
      id: deliveryBasesTypeCheck(id),
      name: name || '',
      abbreviation,
      active,
    })),
    buyerCultures: template.buyer.buyerCultures.map(({ id, name, qualityIndicators, image, mainImage, position }) => ({
      id,
      name: name || '',
      qualityIndicators,
      image: image || '',
      mainImage,
      position,
    })),
    defaultStore: {
      ...template.buyer.defaultStore,
      location: {
        ...template.buyer.defaultStore?.location,
        address: template.buyer.defaultStore?.location.address || '',
        default: !!template.buyer.defaultStore?.location.default,
        id: template.buyer.defaultStore?.location.id || '',
        latitude: template.buyer.defaultStore?.location.latitude || 0,
        longitude: template.buyer.defaultStore?.location.longitude || 0,
        creationDate: template.buyer.defaultStore?.location.creationDate || '',
        fiasID: template.buyer.defaultStore?.location.fiasID || '',
        kind: {
          ...template.buyer.defaultStore?.location.kind,
          id: enumFromStringValue(EVariablesKind, template.buyer.defaultStore?.location.kind.id) || EVariablesKind.warehouse,
          name: template.buyer.defaultStore?.location.kind.name || '',
        },

        region: {
          ...template.buyer.defaultStore?.location.region,
          code: template.buyer.defaultStore?.location.region.code || 77,
          name: template.buyer.defaultStore?.location.region.name || '',
        },
      },
    },
  },
  culture: { ...template.culture, image: template.culture.image || '' },
  elevator: {
    ...template.elevator,
    id: template.elevator?.id || '',
    name: template.elevator?.name || '',
    location: {
      ...template.elevator?.location,
      address: template.elevator?.location.address || '',
      default: !!template.elevator?.location.default,
      id: template.elevator?.location.id || '',
      latitude: template.elevator?.location.latitude || 0,
      longitude: template.elevator?.location.longitude || 0,
      creationDate: template.elevator?.location.creationDate || '',
      fiasID: template.elevator?.location.fiasID || '',
      kind: {
        ...template.elevator?.location.kind,
        id: enumFromStringValue(EVariablesKind, template.elevator?.location.kind.id) || EVariablesKind.warehouse,
        name: template.elevator?.location.kind.name || '',
      },
      region: {
        ...template.elevator?.location.region,
        code: template.elevator?.location.region.code || 77,
        name: template.elevator?.location.region.name || '',
      },
    },
  },
  status: enumFromStringValue(EPurchaseStatus, template.status),
})
