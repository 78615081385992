import partition from 'lodash/partition'
import type { QualityIndicatorsData } from '@/api/generated/base'
import { transformIndicatorsToQualityIndicators } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/helpers'
import type { IRequestQualityIndicators } from '@/types/HarvestSaleLots'

export const transformQualityIndicatorsData = (data?: QualityIndicatorsData[]): IRequestQualityIndicators => {
  const { indicators } = data?.[0] || {}

  if (!indicators) {
    return {
      optionalQualityIndicators: [],
      requiredQualityIndicators: [],
    }
  }

  const [requiredQualityIndicators, optionalQualityIndicators] = partition(transformIndicatorsToQualityIndicators(indicators), 'required')
  const transformedQualityIndicators = requiredQualityIndicators.map(el => ({ ...el, required: false }))

  return { requiredQualityIndicators: transformedQualityIndicators, optionalQualityIndicators }
}
